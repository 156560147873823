
  import { Component, Vue } from 'vue-property-decorator';
  import { validationMixin } from 'vuelidate';
  import { email, required } from 'vuelidate/lib/validators';

  @Component({
    mixins: [ validationMixin ],
    validations: {
      auth: {
        login: { required, email },
        password: { required }
      }
    }
  })
  export default class Login extends Vue {
    public auth: object = {
      login: '',
      password: ''
    };
    public error: string = '';
    public errorStatus: string = '';

    public async login() {
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      try {
        const response = await this.$auth.login(this.auth);

        if (response.data.user.role_id !== 1) {
          this.error = (this.$t('login.errors.accessDenied') as string);

          this.$auth.logout();
        }
      } catch (e: any) {
        if (e && e.response) {
          this.errorStatus = e.response.status;
          this.error = e.response.data.message;
        }

        this.$auth.logout();
      }
    }
  }
